import { EuiSkeletonText, EuiSpacer, EuiTitle } from '@elastic/eui';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { useStores } from '../../hooks';
import { logger } from '../../utils';
import { CommentEditor, CommentList } from '../comment';

export interface ReportCommentManagerProps {
  /**
   * ID of the report these comments are associated.
   */
  reportId: string;
  /**
   * Set to `true` to allow the current user to create comments.
   */
  canComment: boolean;
}

/**
 * Renders the activity comment list for a report, and manages user creates,
 * edits, and deletes of comments.
 */
export const ReportCommentManager = observer(
  (props: ReportCommentManagerProps) => {
    const { reportId, canComment } = props;
    const { dialogStore, reportCommentStore, toastStore } = useStores();

    const { orderedItems: reportComments } = reportCommentStore.data;

    const handleSubmitComment = async (value: string) => {
      try {
        await reportCommentStore.createComment(reportId, {
          content: value,
        });
      } catch (error) {
        logger.error('ReportCommentManager.handleSubmitComment', error);
        await toastStore.showApiError(error);
      }
    };

    const handleUpdateComment = async (commentId: string, content: string) => {
      try {
        await reportCommentStore.updateComment(reportId, commentId, content);
      } catch (error) {
        logger.error('ReportCommentManager.handleUpdateComment', error);
        await toastStore.showApiError(error);
      }
    };

    const handleDeleteComment = (commentId: string) => async () => {
      try {
        await reportCommentStore.deleteComment(reportId, commentId);
      } catch (error) {
        logger.error('ReportCommentManager.handleDeleteComment', error);
        await toastStore.showApiError(error);
      }
    };

    const handleConfirmDelete = (commentId: string) => {
      dialogStore.openDialog({
        title: 'Delete comment?',
        content: <p>This cannot be undone!</p>,
        onConfirm: handleDeleteComment(commentId),
        confirmText: 'Delete',
        isDelete: true,
      });
    };

    return (
      <Fragment>
        <Fragment>
          <EuiTitle size="s">
            <h2>Activity</h2>
          </EuiTitle>
          <EuiSpacer size="m" />
          <CommentList
            commentDtos={reportComments}
            onUpdate={handleUpdateComment}
            onDelete={handleConfirmDelete}
          />
        </Fragment>
        {reportCommentStore.creating ? (
          <EuiSkeletonText
            isLoading
            lines={2}
            contentAriaLabel="Loading new comment"
          />
        ) : null}
        {canComment ? (
          <Fragment>
            <EuiSpacer />
            <CommentEditor onSubmitComment={handleSubmitComment} />
          </Fragment>
        ) : null}
      </Fragment>
    );
  },
);
