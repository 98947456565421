import { CriteriaWithPagination, EuiSpacer } from '@elastic/eui';
import { ReportStatus } from '@unfrl/copdb-sdk';
import { observer } from 'mobx-react';
import { useSearchParams } from 'react-router-dom';
import { useReportFetch } from '../../hooks';
import { ReportDetails } from '../../stores';
import { ReportTable } from './report-table';
import { ReportTableToolbar } from './report-table-toolbar';

export const ReportModerateTableContainer = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();

  const status = (searchParams.get('status') as ReportStatus) ?? undefined;
  const page = parseInt(searchParams.get('page') ?? '1');
  const pageSize = parseInt(searchParams.get('pageSize') ?? '10');

  const { result, loading } = useReportFetch({
    status,
    page,
    pageSize,
  });

  const handleTableChange = ({
    page,
  }: CriteriaWithPagination<ReportDetails>) => {
    setSearchParams((params) => ({
      ...params,
      ...(status ? { status } : undefined),
      page: (page.index + 1).toString(),
      pageSize: page.size.toString(),
    }));
  };

  const handleStatusChange = (newStatus?: ReportStatus) => {
    setSearchParams((params) => ({
      ...params,
      ...(newStatus ? { status: newStatus } : undefined),
    }));
  };

  return (
    <>
      <ReportTableToolbar
        isModerating
        status={status}
        onStatusChange={handleStatusChange}
      />
      <EuiSpacer size="s" />
      <ReportTable
        result={result}
        loading={loading}
        onTableChange={handleTableChange}
      />
    </>
  );
});
