export const homePath = () => '/';

export const incidentPath = (id: string) => `/incidents/${id}`;

export const incidentsPath = () => '/incidents';

export const departmentsPath = () => '/departments';

export const departmentProfilePath = (departmentId: string) =>
  `/departments/${departmentId}`;

export const copsPath = () => '/cops';

export const copsDepartmentFilterPath = (departmentId: string) =>
  `/cops?departmentId=${departmentId}`;

export const copProfilePath = (id: string) => `/cops/${id}`;

export const reportsPath = () => `/reports`;

export const reportPath = (reportId: string) => `/reports/${reportId}`;

export const reportEditPath = (reportId: string) => `/reports/${reportId}/edit`;

export const signupPath = () => '/signup';

export const loginPath = () => '/login';

export const forgotPasswordPath = () => '/forgot-password';

export const usersPath = () => '/users';

export const userProfilePath = () => '/user-profile';

export const mediaDetailsPath = (id: string) => `/medias/${id}`;

export const mediaEditsPath = () => '/media-edits';

export const moderatePath = (tab?: string) =>
  `/moderate${tab ? `?tab=${tab}` : ''}`;

export const verifyEmailPath = (email: string) =>
  `/verify-email?email=${email}`;
