import { observer } from 'mobx-react';
import { useStores } from '../../hooks';
import { AuthStatus } from '../../stores';
import { ResourceNotFound } from '../common';

export interface AuthRequiredProps {
  children: JSX.Element;
  isRoute?: boolean;
}

export const AuthRequired = observer((props: AuthRequiredProps) => {
  const { children, isRoute } = props;
  const { authStore } = useStores();

  if (authStore.authenticated) {
    return children;
  }

  if (!isRoute) {
    return null;
  }

  if (authStore.status !== AuthStatus.Ready) {
    // for routes, prevent brief flash of 404 if still loading
    return null;
  }

  return <ResourceNotFound />;
});
