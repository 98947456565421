import {
  EuiHorizontalRule,
  EuiIcon,
  EuiPanel,
  EuiText,
  EuiToolTip,
} from '@elastic/eui';
import { MediaAssociationDto } from '@unfrl/copdb-sdk';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react';
import { CSSProperties } from 'react';
import { useStores } from '../../hooks';
import { formatName } from '../../utils';
import { Flex } from '../common';
import { MediaPreview } from '../media/media-preview';

export interface MediaAssociationEditDiffProps {
  before: MediaAssociationDto[];
  after: MediaAssociationDto[];
}

export const MediaAssociationEditDiff = observer(
  (props: MediaAssociationEditDiffProps) => {
    const { before, after } = props;
    const { mediaStore, copStore } = useStores();

    const mediaPanelStyle: CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: 90,
      minWidth: 250,
    };

    const editedMediaAssociations: {
      mediaId: string;
      original: MediaAssociationDto;
      edited: MediaAssociationDto;
    }[] = [];

    // Populate editedMediaAssociations. It holds info on media whose associations were edited
    before.forEach((beforeAssociation) => {
      const editedMediaAssociation = after.find(
        (afterAssociation) =>
          afterAssociation.mediaId === beforeAssociation.mediaId &&
          !isEqual(afterAssociation, beforeAssociation),
      );
      if (editedMediaAssociation) {
        editedMediaAssociations.push({
          mediaId: beforeAssociation.mediaId,
          original: beforeAssociation,
          edited: editedMediaAssociation,
        });
      }
    });

    const elementResults: React.ReactElement[] = [];

    const renderAssociation = (
      original: MediaAssociationDto | undefined,
      edited: MediaAssociationDto | undefined,
    ): React.ReactElement | null => {
      const allPersonIds = Array.from(
        new Set<string>([
          ...(original?.personIds ?? []),
          ...(edited?.personIds ?? []),
        ]),
      );
      if (!original?.mediaId && !edited?.mediaId) {
        return null;
      }
      const mediaId = original?.mediaId ?? edited?.mediaId!;

      const media = mediaStore.data.getItem(mediaId);

      let panelColor: 'plain' | 'success' | 'danger' = 'plain';
      if (original === undefined) {
        panelColor = 'success';
      } else if (edited === undefined) {
        panelColor = 'danger';
      }
      return (
        <EuiPanel
          grow={false}
          hasBorder
          key={mediaId}
          color={panelColor}
          paddingSize="s"
          style={mediaPanelStyle}
        >
          {media && (
            <div style={{ maxWidth: 300 }}>
              <MediaPreview media={media} />
            </div>
          )}
          <EuiHorizontalRule margin="s" />

          {original?.includesIncident !== edited?.includesIncident ? (
            <Flex align="center">
              <EuiText color="subdued">Associated with incident: </EuiText>
              <div style={{ marginRight: 8 }} />
              <EuiText
                color={!edited?.includesIncident ? 'danger' : 'success'}
              >{`${Boolean(edited?.includesIncident)}`}</EuiText>
            </Flex>
          ) : null}

          {!isEqual(original?.personIds, edited?.personIds)
            ? allPersonIds.map((personId) => {
                const cop = copStore.data.orderedItems.find(
                  (cop) => cop.personId === personId,
                );
                const originalContains = Boolean(
                  original?.personIds &&
                    original?.personIds.indexOf(personId) > -1,
                );
                const editedContains = Boolean(
                  edited?.personIds && edited?.personIds.indexOf(personId) > -1,
                );

                if (originalContains && editedContains) {
                  // This person will remain associated
                  return (
                    <EuiToolTip
                      key={personId}
                      content={
                        <p>
                          This media will remain associated with{' '}
                          {formatName(
                            cop?.person.firstName,
                            cop?.person.middleName,
                            cop?.person.lastName,
                          )}
                        </p>
                      }
                    >
                      <Flex align="center" gap={8}>
                        <EuiIcon type="iInCircle" color="plain" />
                        <EuiText>
                          {formatName(
                            cop?.person.firstName,
                            cop?.person.middleName,
                            cop?.person.lastName,
                          )}
                        </EuiText>
                      </Flex>
                    </EuiToolTip>
                  );
                }

                if (originalContains && !editedContains) {
                  // This person was unassociated
                  return (
                    <EuiToolTip
                      key={personId}
                      content={
                        <p>
                          This media will be <strong>unassociated</strong> from{' '}
                          {formatName(
                            cop?.person.firstName,
                            cop?.person.middleName,
                            cop?.person.lastName,
                          )}
                        </p>
                      }
                    >
                      <Flex align="center" gap={8}>
                        <EuiIcon type="cross" color="danger" />
                        <EuiText>
                          {formatName(
                            cop?.person.firstName,
                            cop?.person.middleName,
                            cop?.person.lastName,
                          )}
                        </EuiText>
                      </Flex>
                    </EuiToolTip>
                  );
                }

                // This person was associated
                return (
                  <EuiToolTip
                    key={personId}
                    content={
                      <p>
                        This media will be <strong>associated</strong> with{' '}
                        {formatName(
                          cop?.person.firstName,
                          cop?.person.middleName,
                          cop?.person.lastName,
                        )}
                      </p>
                    }
                  >
                    <Flex align="center" gap={8}>
                      <EuiIcon type="plus" color="success" />
                      <EuiText>
                        {formatName(
                          cop?.person.firstName,
                          cop?.person.middleName,
                          cop?.person.lastName,
                        )}
                      </EuiText>
                    </Flex>
                  </EuiToolTip>
                );
              })
            : null}
        </EuiPanel>
      );
    };

    // Add media that were removed to elementResults
    before.forEach((beforeAssociation) => {
      if (
        after.some(
          (afterAssociation) =>
            afterAssociation.mediaId === beforeAssociation.mediaId,
        )
      ) {
        return;
      }
      const result = renderAssociation(beforeAssociation, undefined);
      if (result) {
        elementResults.push(result);
      }
    });

    // Add media that were added to elementResults
    after.forEach((afterAssociation) => {
      if (
        before.some(
          (beforeAssociation) =>
            beforeAssociation.mediaId === afterAssociation.mediaId,
        )
      ) {
        return;
      }
      const result = renderAssociation(undefined, afterAssociation);
      if (result) {
        elementResults.push(result);
      }
    });

    // Add edited media associations to elementResults
    editedMediaAssociations.forEach((association) => {
      const result = renderAssociation(
        association.original,
        association.edited,
      );
      if (result) {
        elementResults.push(result);
      }
    });

    return (
      <EuiPanel>
        <EuiText color="subdued">{'Media'}:</EuiText>
        <Flex wrap align={'center'} gap={8}>
          {elementResults}
        </Flex>
      </EuiPanel>
    );
  },
);
