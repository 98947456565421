import { ReportFilterResponse, ReportStatus } from '@unfrl/copdb-sdk';
import { useEffect, useState } from 'react';
import { apiClient } from '../api';
import { logger } from '../utils';
import { useStores } from './useStores';

export interface ReportFetchOptions {
  /**
   * Optionally filter report results by the user ID who created the report.
   */
  userId?: string;
  /**
   * Optional status to filter the reports by.
   */
  status?: ReportStatus;
  /**
   * 1-based index for retrieving the reports.
   */
  page: number;
  pageSize: number;
}

/**
 * Handles fetching (and refetching) reports when the provided options are
 * changed. Returns the results and whether an active request is still loading.
 */
export const useReportFetch = ({
  userId,
  status,
  page,
  pageSize,
}: ReportFetchOptions) => {
  const { toastStore } = useStores();
  const [result, setResult] = useState<ReportFilterResponse>({
    pageNumber: page,
    pageSize,
    count: 0,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let ignore = false;

    const fetchReports = async () => {
      try {
        setLoading(true);

        const result = await apiClient.reports.listReports({
          userId,
          status,
          page,
          pageSize,
        });

        if (!ignore) {
          setResult(result);
        }
      } catch (error) {
        await toastStore.showApiError(error);
        logger.error('fetchReports', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();

    return () => {
      ignore = true;
    };
  }, [status, userId, page, pageSize, toastStore]);

  return { result, loading };
};
