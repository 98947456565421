import { EuiConfirmModal } from '@elastic/eui';
import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

export interface NavigationBlockerProps {
  when: boolean;
  message: string;
  title: string;
}

/**
 * Displays a confirm prompt when the `when` condition is true.
 * Handles client-side navigation and beforeunload event.
 * A custom modal is displayed on client-side prompt, but
 * the default dialog is used for unload event.
 */
export const NavigationBlocker = ({
  when,
  message,
  title,
}: NavigationBlockerProps) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      when && currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };

    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [when]);

  if (blocker.state !== 'blocked') {
    return null;
  }

  return (
    <EuiConfirmModal
      title={title}
      onCancel={() => blocker.reset()}
      onConfirm={() => blocker.proceed()}
      confirmButtonText="Ok"
      cancelButtonText="Cancel"
    >
      {message}
    </EuiConfirmModal>
  );
};
