import { EuiHighlight, EuiText } from '@elastic/eui';
import { observer } from 'mobx-react';
import { Link, useLocation } from 'react-router-dom';

export interface TitleCellProps {
  title: string;
  linkTo: string;
  searchValue?: string;
}

export const TitleCell = observer((props: TitleCellProps) => {
  const { title, linkTo, searchValue } = props;
  const location = useLocation();

  const text = searchValue ? (
    <EuiHighlight search={searchValue.trim().replaceAll(/\s{2,}/g, ' ')}>
      {title}
    </EuiHighlight>
  ) : (
    <EuiText size="relative">{title}</EuiText>
  );

  return (
    <Link to={linkTo} state={{ from: location }}>
      {text}
    </Link>
  );
});
