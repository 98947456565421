import {
  EuiConfirmModal,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  useGeneratedHtmlId,
} from '@elastic/eui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiClient } from '../../api';
import { useStores } from '../../hooks';
import { reportEditPath } from '../../utils';

export interface NewReportModalProps {
  onClose: () => void;
}

export const NewReportModal = observer((props: NewReportModalProps) => {
  const { onClose } = props;
  const navigate = useNavigate();
  const { toastStore } = useStores();
  const [title, setTitle] = useState('');
  const [creating, setCreating] = useState(false);
  const modalFormId = useGeneratedHtmlId({ prefix: 'newReportModal' });
  const modalTitleId = useGeneratedHtmlId();

  const handleSave = async () => {
    try {
      setCreating(true);

      const response = await apiClient.reports.createReport({
        upsertReportDto: {
          title,
        },
      });

      navigate(reportEditPath(response.report.id), { replace: true });
      onClose();
    } catch (error) {
      toastStore.showApiError(error);
    } finally {
      setCreating(false);
    }
  };

  const handleCancel = () => {
    if (!creating) {
      onClose();
    }
  };

  return (
    <EuiConfirmModal
      aria-labelledby={modalTitleId}
      title="New report"
      titleProps={{ id: modalTitleId }}
      onCancel={handleCancel}
      onConfirm={handleSave}
      confirmButtonText={creating ? 'Creating...' : 'Create'}
      cancelButtonText="Cancel"
      isLoading={creating}
      initialFocus="[name=reportTitle]"
    >
      <EuiForm
        id={modalFormId}
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
      >
        <EuiFormRow
          label="Report title"
          helpText="Please enter a title summarizing what you are reporting on. This can be changed later on."
        >
          <EuiFieldText
            name="reportTitle"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </EuiFormRow>
      </EuiForm>
    </EuiConfirmModal>
  );
});
