import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import { UserProfileDto } from '@unfrl/copdb-sdk';
import { logger } from '../utils';
import { IS_PRODUCTION, RTM_API_PATH } from './config';
import { BaseMethods, ReportMethods } from './rtm-methods';

export class RtmClient {
  private _accessToken: string = '';

  private _connection: HubConnection;

  public readonly reports: ReportMethods;

  public readonly user: Pick<BaseMethods<UserProfileDto>, 'onUpdated'>;

  public readonly media: Pick<
    BaseMethods<string>,
    'onUpdated' | 'connect' | 'disconnect' | 'onProgress'
  >;

  public constructor() {
    this._connection = new HubConnectionBuilder()
      .withUrl(RTM_API_PATH, {
        accessTokenFactory: () => this._accessToken,
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true,
      })
      .withAutomaticReconnect({
        // TODO: overriding default exp. backoff and instead just trying to reconnect eve
        nextRetryDelayInMilliseconds: () => 1000,
      })
      .configureLogging(IS_PRODUCTION ? LogLevel.Error : LogLevel.Information)
      .build();

    this.reports = new ReportMethods(this._connection);
    this.user = new BaseMethods(this._connection, 'User');
    this.media = new BaseMethods(this._connection, 'Media');
  }

  public start = async (): Promise<void> => {
    logger.info('rtm', 'starting...');

    if (this._connection.state !== HubConnectionState.Disconnected) {
      logger.warn(
        'tried to start a hub connection that was not in disconnected state',
      );
    } else {
      await this._connection.start();
    }
  };

  public stop = async (): Promise<void> => {
    logger.info('rtm', 'stopping...');

    if (this._connection.state === HubConnectionState.Connected) {
      await this._connection.stop();
    } else {
      logger.warn(
        'attempted to stop a connection that was not in connected state',
        { connectionState: this._connection.state },
      );
    }
  };

  public onStopped(cb: () => void): void {
    this._connection.onclose(cb);
  }

  public onReconnecting(cb: () => void): void {
    this._connection.onreconnecting(cb);
  }

  public onReconnected(cb: () => void): void {
    this._connection.onreconnected(cb);
  }

  public setAccessToken(token: string): void {
    this._accessToken = token;
  }
}

export const rtmClient = new RtmClient();
